import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { config } from './constants'

export default function sentry(app, router) {
  Sentry.init({
    app,
    dsn: config.sentryDsn,
    release: config.appVersion,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [/https?:\/\/.+\.section-l\.co/, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
  })
}
